<template>
  <div>
    <main v-if="page" :class="page.padding && [config.padding[page.padding]]">
      <cms-layout-article v-if="page.templateName === 'article'" :page>
        <cms-section
          v-for="(section, key, i) in page?.sections"
          :key="section.name"
          :first-section="i === 0"
          page-context
          :section
        />
      </cms-layout-article>

      <cms-section
        v-else
        v-for="(section, key, i) in page?.sections"
        :key="section.name"
        :first-section="i === 0"
        page-context
        :section
      />
    </main>
  </div>
</template>

<script lang="ts" setup>
import type { PageContent } from '#types/page'

definePageMeta({
  validate({ path }) {
    return !/\.\w{2,5}$/.test(path)
  },
  async middleware({ params: { slug }, meta }) {
    const { getPage, getSeoMetadata } = useCms()
    const cmsPageState = useState<PageContent>('cmsPage')
    const pageError = useState<any>('cmsPageError')
    const { $feature } = useNuxtApp()
    try {
      pageError.value = null
      const page = await getPage(slug)

      if (page) {
        const { metadata, link } = getSeoMetadata(page)
        useSeoMeta(metadata)
        useHead(() => ({ link }))
        cmsPageState.value = page
        meta.header = {
          transparent: $feature.tempHeaderVariation === 'basic' ? false : page.overlayHeader
        }
      }
    }
    catch (error) {
      pageError.value = error
    }
  }
})

const page = useState<PageContent>('cmsPage').value
const pageError = useState<PageContent>('cmsPageError')

const route = useRoute()
const config = useAppConfig().pages.cms
const slug = useRouteParams<string>('slug')
const { monetateConfig } = useFeatureFlags()
const { $gtm, $sendExtraMonetateEvents } = useNuxtApp()
const cart = useCartStore()

// defines markup via unhead-schema for search functionality on homepage
// https://unhead.unjs.io/schema-org/getting-started/how-it-works
useSchemaOrg([
  defineWebSite({
    potentialAction: [
      defineSearchAction({
        target: '/search?q={search_term_string}'
      })
    ]
  })
])

watch(pageError, () => {
  if (pageError.value)
    throw createError({ ...pageError.value, fatal: true })
}, { immediate: true })

onMounted(async () => {
  $gtm.push('page.onLoadPageData', route, {
    pageTitle: await getPageTitle(),
    pageCategory: slug.value ? 'Content' : 'Home',
    pageName: slug.value ? page?.name : 'Home'
  })

  const mainSlug = slug.value?.[0]

  if (monetateConfig?.extraMonetateEventsPages?.includes(mainSlug) || !mainSlug)
    $sendExtraMonetateEvents()

  $gtm.push('user.onLoadUserData', await getUserEventData())
  $gtm.push('page.onRouterChange', route)
  $gtm.push('cart.onCartLoad', cart)
})
</script>
